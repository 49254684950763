/* for global css */
$color-red: #ff0000;
$color-green: #00ff00;
$color-blue: #0000ff;
$color-primary: #0c213b;
/* #4a6884 */
$color-danger: crimson;

$global-font: "Muli";

$color-error: indianred;

body {
  font-family: $global-font !important;
}

// body ::-webkit-scrollbar {
//   -webkit-appearance: none;
//   width: 8px !important;
//   height: 4px !important;
//   background: white;
// }

// body ::-webkit-scrollbar-thumb {
//   background: #0c213b;
// }

body::-webkit-scrollbar {
    -webkit-appearance: none;
  width: 8px !important;
  height: 4px !important;
  background: white;
}
 
// body::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }
 
body::-webkit-scrollbar-thumb {
  background-color: #0c213b;;
  // outline: 1px solid #0c213b;;
}


.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  pointer-events: auto !important;
  opacity: 0.45;
  cursor: not-allowed;
}

// .disabled {
//   pointer-events: none !important;
//   opacity: 0.45;
//   cursor: none;
// }

.react-confirm-alert-button-group > button {
  border-radius: 32px !important;
  border: 1px solid $color-primary;
  background: transparent;
  color: $color-primary;
  height: 2.3em;
  padding: 0.5em 1.5em 0.78571429em;
  font-family: $global-font;
}

.dimmed.dimmable>.ui.animating.dimmer, .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer{
  z-index: auto;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//IE Browser Compatibility issues

.ui.modal{
  position: static !important;
}

.dimmed.dimmable >
.ui.animating.dimmer,
.dimmed.dimmable >
.ui.visible.dimmer,
.ui.active.dimmer{
  z-index: 10 !important;
}

.ui.modal{
  position: static !important;
}

.dimmed.dimmable >
.ui.animating.dimmer,
.dimmed.dimmable >
.ui.visible.dimmer,
.ui.active.dimmer{
  z-index: 10!important;
}

  .ui.dropdown .menu{
    z-index: 1!important;
  }

.login-version-container{
  position: fixed;
  bottom: 0px;
  overflow: auto;

}
.floated-pagination{
    z-index: 0!important;
}
.login-conatiner .login .login-wrapper{
  margin-bottom: 6em;
}
.forgot-password-version{
  position: absolute;
  bottom: 1rem;
  overflow: auto;
}

.forgot-password-wrapper .footer-section{
  margin-bottom: -1em!important;
}
.view-edit-model-wrapper .close-btn-wrapper .close-btn{
  width: 30px;
  padding: 5px;
  float:right;
  margin-right: -3rem;
  margin-top:-1.3rem;
}

.admin-console-wrapper .admin-main-container{
  display: flex;
}
.floor-management-wrapper {
    flex: 1 1;
    padding: 1em;
}
.floor-management-wrapper .floor-map-wrapper {
    margin-top: 1em;
    display: flex;
}
.floor-management-wrapper .floor-map-wrapper .container_1 .devices-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 25em;
    margin-bottom: 1em;
}
.floor-management-wrapper .floor-map-wrapper .container_1 .devices-info-wrapper {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    min-height: 25em;
}


.viewFloorMap-wrapper {
  display: ruby-base-container!important;
  padding: 1em;
}
.viewFloorMap-wrapper .floor-map-wrapper {
  margin-top: 1em;
  display: flex;
}
.viewFloorMap-wrapper .floor-map-wrapper .container_1 .devices-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 25em;
  margin-bottom: 1em;
}
.viewFloorMap-wrapper .floor-map-wrapper .container_1 .devices-info-wrapper {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  min-height: 25em;
}


.header-container .version-wrapper{
  flex: 0.3 1;
}

.dashboard-wrapper .global-filter .dropdown .menu.transition.visible{
  position: relative;
  left:84rem;
  top:1.5rem;
  min-height: 7rem;
}
.dashboard-wrapper .kpi-container .kpi-card .title .title-right-content .refresh_btn{
  z-index: 0;
}

.header-container .user-profile-dropdown{
  z-index: 7!important;
}
.world-view-wrapper .map-wrapper #map .gm-style .gm-style-iw .popover-wrapper .popover-row .popover-col-machines .popover-col3{
  margin: 0 1em;
  display: inline-table;
}
.forgot-password-wrapper .forgot-password-box{
  position: absolute!important;
}
.site-view-wrapper .user-table .table-header{
  position: static!important;
}
.site-view-wrapper .user-table{
  display: block!important;
  flex-direction: column!important;
}
.floor-management-wrapper .header-wrapper .floor-container .no-data-msg{
  font-size: 12px !important;
  width: 17rem;
}
.dashboard-wrapper .col-charts .row-charts
.device-color-proportion-wrapper
.title .title-right-content
.filter-btn .filter-icon{
  margin-left: 0em!important;
}
.dashboard-wrapper .col-charts .row-charts
.average-volume-wrapper .title
 .title-right-content .filter-btn .filter-icon{
   margin-left: 0em!important;
 }
 .dashboard-wrapper .col-charts
 .page-usage-wrapper
 .title .title-right-content
 .filter-btn .filter-icon{
   margin-left: 0em!important;
 }
}
