body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    font-family:'Muli'!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spinner_background {
  background-color: #4a6884;
  background-size: cover;
  display: block;
  z-index: 3000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  outline: 0;
  min-height: 100vh;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center; }

.spinner_container {
  z-index: 3000;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.spinner {
  width: 110px;
  height: 100px;
  font-size: 10px; }

.spinner > div {
  background-color: white;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin: 2px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out; }

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

.processing-text {
  z-index: 3000;
  position: absolute;
  top: 70%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 800; }

.tcoExportProcessing-text {
  z-index: 3000;
  position: absolute;
  font-size: 20px;
  top: 70%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: white;
  background-color: #0c213b !important;
  font-weight: 800; }

/* for global css */
/* #4a6884 */
body {
  font-family: "Muli" !important; }

body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px !important;
  height: 4px !important;
  background: white; }

body::-webkit-scrollbar-thumb {
  background-color: #0c213b; }

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  pointer-events: auto !important;
  opacity: 0.45;
  cursor: not-allowed; }

.react-confirm-alert-button-group > button {
  border-radius: 32px !important;
  border: 1px solid #0c213b;
  background: transparent;
  color: #0c213b;
  height: 2.3em;
  padding: 0.5em 1.5em 0.78571429em;
  font-family: "Muli"; }

.dimmed.dimmable > .ui.animating.dimmer, .dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
  z-index: auto; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ui.modal {
    position: static !important; }
  .dimmed.dimmable >
.ui.animating.dimmer,
  .dimmed.dimmable >
.ui.visible.dimmer,
  .ui.active.dimmer {
    z-index: 10 !important; }
  .ui.modal {
    position: static !important; }
  .dimmed.dimmable >
.ui.animating.dimmer,
  .dimmed.dimmable >
.ui.visible.dimmer,
  .ui.active.dimmer {
    z-index: 10 !important; }
  .ui.dropdown .menu {
    z-index: 1 !important; }
  .login-version-container {
    position: fixed;
    bottom: 0px;
    overflow: auto; }
  .floated-pagination {
    z-index: 0 !important; }
  .login-conatiner .login .login-wrapper {
    margin-bottom: 6em; }
  .forgot-password-version {
    position: absolute;
    bottom: 1rem;
    overflow: auto; }
  .forgot-password-wrapper .footer-section {
    margin-bottom: -1em !important; }
  .view-edit-model-wrapper .close-btn-wrapper .close-btn {
    width: 30px;
    padding: 5px;
    float: right;
    margin-right: -3rem;
    margin-top: -1.3rem; }
  .admin-console-wrapper .admin-main-container {
    display: flex; }
  .floor-management-wrapper {
    flex: 1 1;
    padding: 1em; }
  .floor-management-wrapper .floor-map-wrapper {
    margin-top: 1em;
    display: flex; }
  .floor-management-wrapper .floor-map-wrapper .container_1 .devices-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 25em;
    margin-bottom: 1em; }
  .floor-management-wrapper .floor-map-wrapper .container_1 .devices-info-wrapper {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    min-height: 25em; }
  .viewFloorMap-wrapper {
    display: ruby-base-container !important;
    padding: 1em; }
  .viewFloorMap-wrapper .floor-map-wrapper {
    margin-top: 1em;
    display: flex; }
  .viewFloorMap-wrapper .floor-map-wrapper .container_1 .devices-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 25em;
    margin-bottom: 1em; }
  .viewFloorMap-wrapper .floor-map-wrapper .container_1 .devices-info-wrapper {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    min-height: 25em; }
  .header-container .version-wrapper {
    flex: 0.3 1; }
  .dashboard-wrapper .global-filter .dropdown .menu.transition.visible {
    position: relative;
    left: 84rem;
    top: 1.5rem;
    min-height: 7rem; }
  .dashboard-wrapper .kpi-container .kpi-card .title .title-right-content .refresh_btn {
    z-index: 0; }
  .header-container .user-profile-dropdown {
    z-index: 7 !important; }
  .world-view-wrapper .map-wrapper #map .gm-style .gm-style-iw .popover-wrapper .popover-row .popover-col-machines .popover-col3 {
    margin: 0 1em;
    display: inline-table; }
  .forgot-password-wrapper .forgot-password-box {
    position: absolute !important; }
  .site-view-wrapper .user-table .table-header {
    position: static !important; }
  .site-view-wrapper .user-table {
    display: block !important;
    flex-direction: column !important; }
  .floor-management-wrapper .header-wrapper .floor-container .no-data-msg {
    font-size: 12px !important;
    width: 17rem; }
  .dashboard-wrapper .col-charts .row-charts
.device-color-proportion-wrapper
.title .title-right-content
.filter-btn .filter-icon {
    margin-left: 0em !important; }
  .dashboard-wrapper .col-charts .row-charts
.average-volume-wrapper .title
.title-right-content .filter-btn .filter-icon {
    margin-left: 0em !important; }
  .dashboard-wrapper .col-charts
.page-usage-wrapper
.title .title-right-content
.filter-btn .filter-icon {
    margin-left: 0em !important; } }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 45px;
  height: 45px; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 6px;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s; }

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.toaster-offline {
  display: flex;
  align-items: center; }

.Toastify__toast {
  z-index: 1001 !important; }

